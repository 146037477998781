// src/components/Projects.js
import React, { useState, useRef } from "react";
import { CalendarIcon, ClockIcon, UserGroupIcon, CodeIcon, ExternalLinkIcon } from "@heroicons/react/outline";

import { useTranslation } from "react-i18next";
import "./Projects.css";
//import project1Image from "../assets/project1.png";
//import project2Image from "../assets/project2.png";
//import project3Image from "../assets/project3.png";

const Projects = () => {
    const { t } = useTranslation();

    const projectsRef = useRef(null);
    // Liste des projets
    const projects = [
        {
            id: 1,
            name: t("DEFT 2024"),
            description: t("1ère place à la Campagne DEFT 2024 (défi de fouille de texte) sur les QCMs de Pharmacie avec des petits LLMs. Le but était d’identifier les réponses correctes parmi cinq options, en respectant des contraintes strictes sur les modèles et les corpus utilisés."),
            type: t("personal"),
            language: "Python, Natural Language Processing (NLP), Retrieval Augmented Generation(RAG), HuggingFace, Appolo2B",
            year: 2024,
            group: true,
            duration: t("2 mois"),
            image: null,
            visibility: "public",
            /*github: "https://talnarchives.atala.org/ateliers/2024/DEFT/3.pdf"*/
        },
        {
            id: 2,
            name: t("LIMICS"),
            description: t("Développement d'un système de questions/réponses basé sur un LLM dans le but de réutiliser le système d'aide à la décision Oncodoc pour la gestion thérapeutique du cancer du sein."),
            language: "Large Languages Model, Système d'aide à la décision (CDSS), Oncodoc, Mistral, Openchat",
            type: t("professional"),
            year: 2024,
            group: false,
            duration: t("5 mois"),
            image: null,
            visibility: "private",

        },
        {
            id: 3,
            name: t("Apprentissage Automatique - Prédiction de Diabète"),
            description: t("Entraînement de modèles d’IA à partir d’un dataset de prédiction de diabète."),
            type: t("School"),
            language: "Python, scikit-learn",
            year: 2023,
            group: true,
            duration: t("1 semaine"),
            image: null,
            visibility: "public",
            github: "https://gitlab.com/solene.delourme/ML_diabete",

        },
        {
            id: 4,
            name: t("Optimisation NLP pour les dialogues SAMU"),
            description: t("Projet d'optimisation d'un modèle de langue pour l'annotation des échanges entre médecins régulateurs du SAMU et les appelants. Le travail inclut l'annotation manuelle via l'outil Brat, l'entraînement et l'évaluation de modèles comme Dr-BERT/DrBERT-7GB et camila-ud/DrBERT-CASM2. Objectif : améliorer la compréhension des dialogues pour optimiser la gestion des urgences médicales."),
            type: t("School"),
            language: "Python, HuggingFace, Brat, Jupyter Notebook",
            year: 2024,
            group: true,
            duration: t("1 semaine"),
            image: null,
            visibility: "private",
            github: null, // Si aucun dépôt GitHub, laisse null
        },
        {
            id: 5,
            name: t("ETL - Entrepôt de Données de Santé"),
            description: t("Création d’un mini-entrepôt de données de santé sous le modèle OMOP CDMV5.4."),
            type: t("School"),
            language: "Docker, R, WhiteRabbit, RabbitInAHat, OHDSI tools",
            year: 2023,
            group: true,
            duration: t("1 mois"),
            image: null,
            visibility: "public",
            github: "https://gitlab.com/solene.delourme/mini_EDS",
        },
        {
            id: 6,
            name: t("Système d’Aide à la Décision et Ontologie"),
            description: t("Construction d’un système d’aide à la décision médicale et d’une ontologie pour l’hypertension. Pas de programmation."),
            type: t("School"),
            language: "Protege, OWL",
            year: 2024,
            group: true,
            duration: t("1 semaine"),
            image: null,
            visibility: "public",
        },
        {
            id: 7,
            name: t("Analyse des Tendances Temporelles de la Tuberculose"),
            description: t("Étude des tendances temporelles de la tuberculose avant, pendant, et après la pandémie de COVID-19."),
            type: t("School"),
            language: "R, PMSI, GHM, ATIH",
            year: 2024,
            group: true,
            duration: t("1 semaine"),
            image: null,
            github: "https://gitlab.com/solene.delourme/research-pulmonary-tuberculosis",
            visibility: "public",

        },
        {
            id: 8,
            name: t("Compétition Kaggle - Réseaux de Neurones"),
            description: t("Détection d’image par réseaux de neurones. Deux compétitions faite sur Kaggle. Première : Navire à la mano, écrire un CNN à la main en moins de 30 couches pour la détection d'images de navires. Deuxième : réutiliser un CNN éxistant pour la détection d'images de navires."),
            type: t("School"),
            language: "Python, Tensorflow, Kaggle",
            year: 2024,
            group: true,
            duration: t("3 mois"),
            image: null,
            visibility: "private",
            github: ["https://www.kaggle.com/competitions/navires-2023-la-mano", "https://www.kaggle.com/competitions/navires-2023-libre"]
        },
        {
            id: 9,
            name: t("Imagerie Médicale et IA"),
            description: t("Utilisation de réseaux de neurones de convolutions (CNNs) dans le but de détecter des tumeurs cérébrales dans des IRM de cerveaux de nourissons."),
            type: t("School"),
            language: "Python, Tensorflow",
            year: 2024,
            group: false,
            duration: t("3 mois"),
            image: null,
            visibility: "private",
        },
        {
            id: 10,
            name: t("Génomique - Expression Génique"),
            description: t("Analyse différentielle sur des données d’expression génique pour le cancer du sein triple négatif (TNBC)."),
            type: t("School"),
            language: "R, GeneCard",
            year: 2024,
            group: false,
            duration: t("2 semaines"),
            image: null,
            visibility: "private",
        },
        {
            id: 11,
            name: t("Bio-informatique - Séquençage de Génome"),
            description: t("Séquencage et reconstruction de novo du génome."),
            type: t("School"),
            language: "Python, NBIC",
            year: 2024,
            group: true,
            duration: t("1 mois"),
            image: null,
            visibility: "private",
        },
        {
            id: 12,
            name: t("IOT"),
            description: t("Création d'une montre connectée sur un STM32"),
            type: t("School"),
            language: "Micropython, STM32",
            year: 2023,
            group: false,
            duration: t("1 mois"),
            image: null,
            visibility: "private",
        },
        {
            id: 13,
            name: t("VO2 Group"),
            description: t("Réappropriation et développement d'une application de priceur pour les obligations financières."),
            type: t("professional"),
            language: "C#, XAML",
            year: 2022,
            group: false,
            duration: t("6 mois"),
            image: null,
            visibility: "private",
        },
        {
            id: 14,
            name: t("42SH"),
            description: t("Création d'un interpreteur Shell"),
            type: t("School"),
            language: "C",
            year: 2022,
            group: true,
            duration: t("2 mois"),
            image: null,
            visibility: "private",
        },
        {
            id: 15,
            name: t("OCR - Optical Character Recognition"),
            description: t("Création d'un outil de reconnaissance optique de caratére avec un réseau de neurone"),
            type: t("School"),
            language: "C, réseaux de neurones",
            year: 2022,
            group: true,
            duration: t("6 mois"),
            image: null,
            visibility: "private",
        },
        {
            id: 16,
            name: t("Jeu vidéo en Réalité Virtuelle"),
            description: t("Création d'un jeu vidéo en réalité virtuelle"),
            type: t("School"),
            language: "C#",
            year: 2022,
            group: true,
            duration: t("6 mois"),
            image: null,
            visibility: "private",
        },
        {
            id: 17,
            name: t("Application entrainement au TOEIC"),
            description: t("Création d’une application locale pour s’entraîner au TOEIC. L’application propose 20 chapitres spécifiques, différents modes d’entraînement, la prononciation des mots en anglais, une bibliothèque interactive pour apprendre des mots issus de livres téléchargés et des statistiques pour suivre les progrès. (démo disponible)"),
            type: t("personal"),
            language: "Python, Json, Flask, BeautifulSoup",
            year: 2024,
            group: false,
            duration: t("1 mois"),
            image: null,
            visibility: "public",
            github: "https://gitlab.com/solene.delourme/toeic-entrainement",
        },
        {
            id: 18,
            name: t("Exploration des données sur la santé mentale - Kaggle Compétitions"),
            description: t("Participation à la compétition Kaggle Playground Series S4E11. L'objectif est d'explorer les données d'une enquête sur la santé mentale pour identifier les facteurs pouvant causer des épisodes de dépression. Le projet inclut la création de modèles d'apprentissage automatique et l'ingénierie des fonctionnalités sur des données synthétiques générées à partir de cas réels."),
            type: t("personal"),
            language: "Python, Machine Learning, Data Analysis",
            year: 2024,
            group: false,
            duration: t("En cours"),
            image: null,
            visibility: "public",
            github: ["https://www.kaggle.com/competitions/playground-series-s4e11"],
        },


    ];

    const [showAll, setShowAll] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("all");
    const [selectedVisibility, setSelectedVisibility] = useState(null);

    // Filtered projects based on category and visibility
    const filteredProjects = projects.filter((project) => {
        if (selectedCategory === "all") return true;
        if (selectedCategory === "school") {
            if (selectedVisibility) return project.type === t("School") && project.visibility === selectedVisibility;
            return project.type === t("School");
        }
        return project.type === selectedCategory;
    });

    // Displayed projects based on `showAll` state
    const displayedProjects = showAll ? filteredProjects : filteredProjects.slice(0, 3);

    const handleShowMore = () => {
        if (showAll && projectsRef.current) {
            // Scroll to the top of the projects section when "Show Less" is clicked
            projectsRef.current.scrollIntoView({ behavior: "smooth" });
        }
        setShowAll(!showAll); // Toggle the state
    };


    return (
        <section id="projects" className="projects" ref={projectsRef}>
            <h2>{t("Projets")}</h2>

            {/* Navbar principale */}
            <nav className="projects-navbar">
                <ul>
                    <li>
                        <button
                            className={selectedCategory === "all" ? "active" : ""}
                            onClick={() => {
                                setSelectedCategory("all");
                                setSelectedVisibility(null);
                            }}
                        >
                            {t("All")}
                        </button>
                    </li>
                    <li>
                        <button
                            className={selectedCategory === "school" ? "active" : ""}
                            onClick={() => setSelectedCategory("school")}
                        >
                            {t("School")}
                        </button>
                    </li>
                    <li>
                        <button
                            className={selectedCategory === "professional" ? "active" : ""}
                            onClick={() => setSelectedCategory("professional")}
                        >
                            {t("Professional")}
                        </button>
                    </li>
                    <li>
                        <button
                            className={selectedCategory === "personal" ? "active" : ""}
                            onClick={() => setSelectedCategory("personal")}
                        >
                            {t("Personal")}
                        </button>
                    </li>

                </ul>
            </nav>

            {/* Sous-barre pour la catégorie School */}
            {selectedCategory === "school" && (
                <nav className="projects-sub-navbar">
                    <ul>
                        <li>
                            <button
                                className={selectedVisibility === null ? "active" : ""}
                                onClick={() => setSelectedVisibility(null)}
                            >
                                {t("All")}
                            </button>
                        </li>
                        <li>
                            <button
                                className={selectedVisibility === "public" ? "active" : ""}
                                onClick={() => setSelectedVisibility("public")}
                            >
                                {t("Public")}
                            </button>
                        </li>
                        <li>
                            <button
                                className={selectedVisibility === "private" ? "active" : ""}
                                onClick={() => setSelectedVisibility("private")}
                            >
                                {t("Private")}
                            </button>
                        </li>
                    </ul>
                </nav>
            )}

            {/* Liste des projets filtrés */}
            <div className="projects-grid">
                {displayedProjects.map((project) => (
                    <div
                        className={`project-card`} key={project.id}
                    >
                        <div className="project-visible">
                            {project.image && <img src={project.image} alt={project.name} className="project-image" />}
                            <h3>{project.name}</h3>
                            <p>{project.description}</p>
                            {project.link && (
                                <a href={project.link} target="_blank" rel="noopener noreferrer" className="project-link">
                                    {t("View Details")}
                                </a>
                            )}
                        </div>

                        <div className="project-hidden">
                            <p className="project-detail">
                                <CodeIcon className="h-5 w-5 text-green-500" /> {project.language}
                            </p>
                            <p className="project-detail">
                                <ClockIcon className="h-5 w-5 text-green-500" /> {project.duration}
                            </p>
                            <p className="project-detail">
                                <CalendarIcon className="h-5 w-5 text-green-500" /> {project.year}
                            </p>
                            <p className="project-detail group-info">
                                <UserGroupIcon className="h-5 w-5 text-green-500" />
                                {project.group
                                    ? project.number === "quatre"
                                        ? t("Projet fait en groupe de quatre")
                                        : t("Projet fait en groupe")
                                    : t("Projet fait seule")}
                            </p>
                            {project.github && Array.isArray(project.github) ? (
                                <div className="project-link-container">
                                    {project.github.map((link, index) => (
                                        <a
                                            key={index}
                                            href={link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="project-link-button-small"
                                        >
                                            <ExternalLinkIcon className="h-4 w-4" /> {t("Kaggle")} {index + 1}
                                        </a>
                                    ))}
                                </div>
                            ) : project.github ? (
                                <a
                                    href={project.github}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="project-link-button"
                                >
                                    <ExternalLinkIcon className="h-5 w-5" /> {t("View on GitLab")}
                                </a>
                            ) : null}
                        </div>
                    </div>
                ))
                }
            </div>
            {/* Bouton "Voir Plus"/"Voir Moins" */}
            <div className="show-more-container">
                <button
                    className="show-more-btn"
                    onClick={handleShowMore}
                >
                    {showAll ? t("Voir moins") : t("Voir plus")}
                </button>
            </div>
        </section >
    );
};

export default Projects;