import React from "react";
import { useTranslation } from "react-i18next";
import { FaLaptop, FaHeartbeat } from "react-icons/fa";
import "./Home.css";

const Home = () => {
    const { t } = useTranslation();

    return (
        <section id="home" className="home">
            <div className="banner">
                <div className="banner-columns">
                    <a href="https://www.epita.fr/?utm_source=google&utm_medium=cpc&utm_campaign=19876241234&utm_content&utm_term&gad_source=1&gbraid=0AAAAADRH0TlRGYfGkUE_7L3eunx8OMwSG&gclid=Cj0KCQiA0fu5BhDQARIsAMXUBOJefXps_HDc6FHuytRFZL6njAPyE5cv-pE8KnZQ6jDAr2i97ewZ2iEaAgY_EALw_wcB" target="_blank" rel="noopener noreferrer">
                        <FaLaptop className="icon-left" />
                    </a>
                    <div className="banner-text">
                        <h1>{t("Solène Delourme")}</h1>
                        <h2>{t("Ingénieure en Informatique")}</h2>
                        <h2>{t("Master en Informatique Biomédicale")}</h2>
                        <h3>{t("Spécialisée en IA et NLP")}</h3>
                    </div>
                    <a href="https://master-sante.sorbonne-universite.fr/mention-sante-publique/mention-sante-publique-m2/informatique-biomedicale/" target="_blank" rel="noopener noreferrer">
                        <FaHeartbeat className="icon-right" />
                    </a>
                </div>
            </div>
            <h4>{t("Bienvenue sur mon site !")}</h4>
            <p>{t("Vous y trouverez mes projets et mes publications.")}</p>
            <p>{t("Je suis activement à la recherche d'opportunités d'emploi. Mon CV et mon résumé sont disponibles ci-dessous.")}</p>
            <p>{t("N'hésitez pas à découvrir mon travail et à prendre contact avec moi !")}</p>
            <div className="home-actions">
                <a href="#about" className="btn">{t("En apprendre plus sur moi")}</a>
                <a href="#projects" className="btn">{t("Voir mes projets")}</a>
            </div>

        </section>
    );
};

export default Home;