// src/components/Navbar.js
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./Navbar.css";

const Navbar = () => {
    const { t, i18n } = useTranslation();
    const [menuOpen, setMenuOpen] = useState(false);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <nav className="navbar">
            <div className="navbar-header">
                <span className="navbar-title">{t("Menu")}</span>
                <button className="menu-toggle" onClick={toggleMenu}>
                    ☰
                </button>
            </div>
            <ul className={`navbar-links ${menuOpen ? "open" : ""}`}>
                <li><a href="#home">{t("Accueil")}</a></li>
                <li><a href="#about">{t("À Propos de Moi")}</a></li>
                <li><a href="#projects">{t("Projets")}</a></li>
                <li><a href="#publications">{t("Publications")}</a></li>
                <li><a href="#contact">{t("Contact")}</a></li>
                {/* Language Selector */}
                <li className="language-switcher">
                    <button onClick={() => changeLanguage("en")}>English</button>
                    <button onClick={() => changeLanguage("fr")}>Français</button>
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;