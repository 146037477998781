// src/components/About.js

import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./About.css";
import photo from "../assets/Photo.JPG"; // Importer la photo

const About = () => {
    const { t } = useTranslation();
    const [showMore, setShowMore] = useState(false);
    const aboutRef = useRef(null); // Référence pour la section "About"

    const handleDownload = (fileName) => {
        const link = document.createElement("a");
        link.href = `/${fileName}`;
        link.download = fileName;
        link.click();
    };

    const toggleShowMore = () => {
        if (showMore && aboutRef.current) {
            // Si "Lire moins" est cliqué, on défile jusqu'à la section "À propos"
            aboutRef.current.scrollIntoView({ behavior: "smooth" });
        }
        setShowMore(!showMore);
    };

    return (
        <section id="about" className="about" ref={aboutRef}>
            <h2 className="section-title">{t("À Propos de Moi")}</h2>

            <div className="about-content">
                {/* Photo */}
                <div className="about-photo-container">
                    <img src={photo} alt={t("Ma Photo")} className="about-photo" />

                    {/* Boutons sous la photo */}
                    <div className="download-buttons">
                        <button
                            className="download-cv-btn"
                            onClick={() => handleDownload("CV_Solene_Delourme_general.pdf")}
                        >
                            {t("Télécharger Mon CV (Français)")}
                        </button>
                        <button
                            className="download-cv-btn"
                            onClick={() => handleDownload("Resume_Solene_Delourme_general.pdf")}
                        >
                            {t("Télécharger Mon Résumé (Anglais)")}
                        </button>
                    </div>
                </div>

                {/* Texte (les deux premiers paragraphes) */}
                <div className="about-text">
                    <div className="about-paragraph">
                        <p>
                            {t("Je suis ingénieure en informatique biomédicale, diplômée de l’EPITA et avec un Master de La Sorbonne Université, et ce parcours a été bien plus qu’un simple diplôme. Il représente pour moi une quête de sens, d’adaptabilité et de dépassement de soi.")}
                        </p>
                    </div>
                    <div className="about-paragraph">
                        <p>
                            {t("Enfant, je voulais devenir professeur, d’abord des écoles, puis de mathématiques. Mais un jour, un conseil m’a ouvert une autre voie : « Ne te ferme aucune porte. » C’est comme ça que j’ai découvert le monde de l’ingénierie, où j’ai appris à résoudre des problèmes concrets, à collaborer et à innover.")}
                        </p>
                    </div>
                </div>
            </div>

            {/* Paragraphes supplémentaires en pleine largeur */}
            {showMore && (
                <div className="about-paragraphs-expanded">
                    <div className="about-paragraph">
                        <p>
                            {t("Pendant mes études, je me suis concentrée sur l’apprentissage académique et collectif, réalisant peu de projets personnels. Depuis que j’ai terminé, j’ai pu en faire deux : un projet lié à la préparation de mon TOEIC et ce site web. Ces expériences m’ont permis de réaliser que « faire un projet pour faire un projet » ne m’intéresse pas : j’ai besoin de savoir que ce que je crée a une utilité réelle.")}
                        </p>
                    </div>
                    <div className="about-paragraph">
                        <p>
                            {t("Travailler dans le domaine de la santé est pour moi une évidence : chaque projet a un sens. Aider des patients, des scientifiques ou des chercheurs, c’est apporter ma pierre à l’édifice. Même un résultat négatif est une avancée, car il permet de mieux comprendre et de progresser.")}
                        </p>
                    </div>
                    <div className="about-paragraph">
                        <p>

                            {t("Ce que je veux faire ? Ce sont les projets concrets qui m'attirent. J’ai exploré beaucoup de domaines comme l’IA, le NLP et le web, et je suis ouverte à un CDI ou à une thèse. Ce n’est pas le type de poste qui compte pour moi, mais le projet derrière : s’il m’intéresse et que je pense pouvoir y contribuer efficacement, c’est là où je veux être.")}
                        </p>

                    </div>
                    <div className="about-paragraph">
                        <p>
                            {t("En tant que junior, je veux continuer à apprendre, explorer et peut-être me spécialiser avec le temps. Mon premier emploi ne définira peut-être pas toute ma carrière, mais je suis convaincue qu’il m’apportera les expériences nécessaires pour construire ma voie.")}
                        </p>
                    </div>
                </div>
            )}

            {/* Bouton "Lire Plus / Lire Moins" */}
            <div className="read-more-container">
                <button
                    className="read-more-btn"
                    onClick={toggleShowMore}
                >
                    {showMore ? t("Lire moins") : t("Lire plus")}
                </button>
            </div>
        </section>
    );
};

export default About;