import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
    fr: {
        translation: {
            "Accueil": "Accueil",
            "À Propos": "À Propos",
            "Projets": "Projets",
            "Publications": "Publications",
            "Contact": "Contact",
            "All": "Tous",
            "School": "Scolaire",
            "Professional": "Professionnel",
            "Personal": "Personnel",
            "Public": "Public",
            "Private": "Privé",
            "View Details": "Voir les Détails",
            "View on GitHub": "Voir sur GitHub",
            "Kaggle": "Kaggle",
            "Voir moins": "Voir moins",
            "Voir plus": "Voir plus",
            "Projet fait en groupe": "Projet fait en groupe",
            "Projet fait en groupe de quatre": "Projet fait en groupe de quatre",
            "Projet fait seule": "Projet fait seule",



            "À Propos de Moi": "À Propos de Moi",
            "Ma Photo": "Ma Photo",
            "Télécharger Mon CV (Français)": "Télécharger Mon CV (Français)",
            "Télécharger Mon Résumé (Anglais)": "Télécharger Mon Résumé (Anglais)",
            "Je suis ingénieure en informatique biomédicale, diplômée de l’EPITA et avec un Master de La Sorbonne Université, et ce parcours a été bien plus qu’un simple diplôme. Il représente pour moi une quête de sens, d’adaptabilité et de dépassement de soi.":
                "Je suis ingénieure en informatique biomédicale, diplômée de l’EPITA et avec un Master de La Sorbonne Université, et ce parcours a été bien plus qu’un simple diplôme. Il représente pour moi une quête de sens, d’adaptabilité et de dépassement de soi.",
            "Enfant, je voulais devenir professeur, d’abord des écoles, puis de mathématiques. Mais un jour, un conseil m’a ouvert une autre voie : « Ne te ferme aucune porte. » C’est comme ça que j’ai découvert le monde de l’ingénierie, où j’ai appris à résoudre des problèmes concrets, à collaborer et à innover.":
                "Enfant, je voulais devenir professeur, d’abord des écoles, puis de mathématiques. Mais un jour, un conseil m’a ouvert une autre voie : « Ne te ferme aucune porte. » C’est comme ça que j’ai découvert le monde de l’ingénierie, où j’ai appris à résoudre des problèmes concrets, à collaborer et à innover.",
            "Pendant mes études, je me suis concentrée sur l’apprentissage académique et collectif, réalisant peu de projets personnels. Depuis que j’ai terminé, j’ai pu en faire deux : un projet lié à la préparation de mon TOEIC et ce site web. Ces expériences m’ont permis de réaliser que « faire un projet pour faire un projet » ne m’intéresse pas : j’ai besoin de savoir que ce que je crée a une utilité réelle.":
                "Pendant mes études, je me suis concentrée sur l’apprentissage académique et collectif, réalisant peu de projets personnels. Depuis que j’ai terminé, j’ai pu en faire deux : un projet lié à la préparation de mon TOEIC et ce site web. Ces expériences m’ont permis de réaliser que « faire un projet pour faire un projet » ne m’intéresse pas : j’ai besoin de savoir que ce que je crée a une utilité réelle.",
            "Travailler dans le domaine de la santé est pour moi une évidence : chaque projet a un sens. Aider des patients, des scientifiques ou des chercheurs, c’est apporter ma pierre à l’édifice. Même un résultat négatif est une avancée, car il permet de mieux comprendre et de progresser.":
                "Travailler dans le domaine de la santé est pour moi une évidence : chaque projet a un sens. Aider des patients, des scientifiques ou des chercheurs, c’est apporter ma pierre à l’édifice. Même un résultat négatif est une avancée, car il permet de mieux comprendre et de progresser.",
            "Je suis curieuse de nature et convaincue que sortir de ma zone de confort m’a toujours enrichie. Mes expériences au LIMICS et chez VO2 Group m’ont permis d’explorer des domaines variés, où mes superviseurs pourront confirmer ma persévérance et ma motivation : « Quand on veut, on peut. »":
                "Je suis curieuse de nature et convaincue que sortir de ma zone de confort m’a toujours enrichie. Mes expériences au LIMICS et chez VO2 Group m’ont permis d’explorer des domaines variés, où mes superviseurs pourront confirmer ma persévérance et ma motivation : « Quand on veut, on peut. »",
            "Ce que je veux faire ? Peut-être que je ne le sais pas encore exactement. Mais ce que je sais, c’est que je suis prête à apprendre, à m’adapter et à relever les défis qui viendront. Mon premier emploi ne définira peut-être pas toute ma carrière, mais je suis convaincue qu’il m’apportera les expériences nécessaires pour construire ma voie.":
                "Ce que je veux faire ? Peut-être que je ne le sais pas encore exactement. Mais ce que je sais, c’est que je suis prête à apprendre, à m’adapter et à relever les défis qui viendront. Mon premier emploi ne définira peut-être pas toute ma carrière, mais je suis convaincue qu’il m’apportera les expériences nécessaires pour construire ma voie.",
            "Lire moins": "Lire moins",
            "Lire plus": "Lire plus",
            "Me Contacter": "Me Contacter",
            "Message envoyé avec succès!": "Message envoyé avec succès!",
            "Erreur lors de l'envoi du message. Essayez encore": "Erreur lors de l'envoi du message. Essayez encore",
            "Nom Prénom": "Nom Prénom",
            "Email": "Email",
            "Message": "Message",
            "Entrez votre prénom": "Entrez votre prénom",
            "Entrez votre email": "Entrez votre email",
            "Entrez votre message": "Entrez votre message",
            "Envoyez votre Message": "Envoyez votre Message",


            // Texte spécifique pour `Navbar.js`
            "English": "Anglais",
            "Français": "Français",

            // Texte spécifique pour `Projects.js`
            "DEFT 2024": "DEFT 2024",
            "1ère place à la Campagne DEFT 2024 (défi de fouille de texte) sur les QCMs de Pharmacie avec des petits LLMs. Le but était d’identifier les réponses correctes parmi cinq options, en respectant des contraintes strictes sur les modèles et les corpus utilisés.": "1ère place à la Campagne DEFT 2024 (défi de fouille de texte) sur les QCMs de Pharmacie avec des petits LLMs. Le but était d’identifier les réponses correctes parmi cinq options, en respectant des contraintes strictes sur les modèles et les corpus utilisés.",

            "LIMICS": "LIMICS",
            "Développement d'un système de questions/réponses basé sur un LLM dans le but de réutiliser le système d'aide à la décision Oncodoc pour la gestion thérapeutique du cancer du sein.": "Développement d'un système de questions/réponses basé sur un LLM dans le but de réutiliser le système d'aide à la décision Oncodoc pour la gestion thérapeutique du cancer du sein.",

            "Apprentissage Automatique - Prédiction de Diabète": "Apprentissage Automatique - Prédiction de Diabète",
            "Entraînement de modèles d’IA à partir d’un dataset de prédiction de diabète.": "Entraînement de modèles d’IA à partir d’un dataset de prédiction de diabète.",

            "ETL - Entrepôt de Données de Santé": "ETL - Entrepôt de Données de Santé",
            "Création d’un mini-entrepôt de données de santé sous le modèle OMOP CDMV5.4.": "Création d’un mini-entrepôt de données de santé sous le modèle OMOP CDMV5.4.",

            "Système d’Aide à la Décision et Ontologie": "Système d’Aide à la Décision et Ontologie",
            "Construction d’un système d’aide à la décision médicale et d’une ontologie pour l’hypertension. Pas de programmation.": "Construction d’un système d’aide à la décision médicale et d’une ontologie pour l’hypertension. Pas de programmation.",

            "Analyse des Tendances Temporelles de la Tuberculose": "Analyse des Tendances Temporelles de la Tuberculose",
            "Étude des tendances temporelles de la tuberculose avant, pendant, et après la pandémie de COVID-19.": "Étude des tendances temporelles de la tuberculose avant, pendant, et après la pandémie de COVID-19.",

            "Compétition Kaggle - Réseaux de Neurones": "Compétition Kaggle - Réseaux de Neurones",
            "Détection d’image par réseaux de neurones. Deux compétitions faite sur Kaggle. Première : Navire à la mano, écrire un CNN à la main en moins de 30 couches pour la détection d'images de navires. Deuxième : réutiliser un CNN éxistant pour la détection d'images de navires.": "Détection d’image par réseaux de neurones. Deux compétitions faite sur Kaggle. Première : Navire à la mano, écrire un CNN à la main en moins de 30 couches pour la détection d'images de navires. Deuxième : réutiliser un CNN éxistant pour la détection d'images de navires.",

            "Imagerie Médicale et IA": "Imagerie Médicale et IA",
            "Utilisation de réseaux de neurones de convolutions (CNNs) dans le but de détecter des tumeurs cérébrales dans des IRM de cerveaux de nourissons.": "Utilisation de réseaux de neurones de convolutions (CNNs) dans le but de détecter des tumeurs cérébrales dans des IRM de cerveaux de nourissons.",

            "Génomique - Expression Génique": "Génomique - Expression Génique",
            "Analyse différentielle sur des données d’expression génique pour le cancer du sein triple négatif (TNBC).": "Analyse différentielle sur des données d’expression génique pour le cancer du sein triple négatif (TNBC).",

            "Bio-informatique - Séquençage de Génome": "Bio-informatique - Séquençage de Génome",
            "Séquencage et reconstruction de novo du génome.": "Séquencage et reconstruction de novo du génome.",

            "IOT": "IOT",
            "Création d'une montre connectée sur un STM32": "Création d'une montre connectée sur un STM32",

            "VO2 Group": "VO2 Group",
            "Réappropriation et développement d'une application de priceur pour les obligations financières.": "Réappropriation et développement d'une application de priceur pour les obligations financières.",

            "42SH": "42SH",
            "Création d'un interpreteur Shell": "Création d'un interpreteur Shell",

            "OCR - Optical Character Recognition": "OCR - Optical Character Recognition",
            "Création d'un outil de reconnaissance optique de caratére avec un réseau de neurone": "Création d'un outil de reconnaissance optique de caratére avec un réseau de neurone",

            "Jeu vidéo en Réalité Virtuelle": "Jeu vidéo en Réalité Virtuelle",
            "Création d'un jeu vidéo en réalité virtuelle": "Création d'un jeu vidéo en réalité virtuelle",

            "Application entrainement au TOEIC": "Application entrainement au TOEIC",
            "Création d’une application locale pour s’entraîner au TOEIC. L’application propose 20 chapitres spécifiques, différents modes d’entraînement, la prononciation des mots en anglais, une bibliothèque interactive pour apprendre des mots issus de livres téléchargés et des statistiques pour suivre les progrès. (démo disponible)": "Création d’une application locale pour s’entraîner au TOEIC. L’application propose 20 chapitres spécifiques, différents modes d’entraînement, la prononciation des mots en anglais, une bibliothèque interactive pour apprendre des mots issus de livres téléchargés et des statistiques pour suivre les progrès. (démo disponible)",

            "Optimisation NLP pour les dialogues SAMU": "Optimisation NLP pour les dialogues SAMU",
            "Projet d'optimisation d'un modèle de langue pour l'annotation des échanges entre médecins régulateurs du SAMU et les appelants. Le travail inclut l'annotation manuelle via l'outil Brat, l'entraînement et l'évaluation de modèles comme Dr-BERT/DrBERT-7GB et camila-ud/DrBERT-CASM2. Objectif : améliorer la compréhension des dialogues pour optimiser la gestion des urgences médicales.": "Projet d'optimisation d'un modèle de langue pour l'annotation des échanges entre médecins régulateurs du SAMU et les appelants. Le travail inclut l'annotation manuelle via l'outil Brat, l'entraînement et l'évaluation de modèles comme Dr-BERT/DrBERT-7GB et camila-ud/DrBERT-CASM2. Objectif : améliorer la compréhension des dialogues pour optimiser la gestion des urgences médicales.",

            "Exploration des données sur la santé mentale - Kaggle Compétitions": "Exploration des données sur la santé mentale - Kaggle Compétitions",
            "Participation à la compétition Kaggle Playground Series S4E11. L'objectif est d'explorer les données d'une enquête sur la santé mentale pour identifier les facteurs pouvant causer des épisodes de dépression. Le projet inclut la création de modèles d'apprentissage automatique et l'ingénierie des fonctionnalités sur des données synthétiques générées à partir de cas réels.": "Participation à la compétition Kaggle Playground Series S4E11. L'objectif est d'explorer les données d'une enquête sur la santé mentale pour identifier les facteurs pouvant causer des épisodes de dépression. Le projet inclut la création de modèles d'apprentissage automatique et l'ingénierie des fonctionnalités sur des données synthétiques générées à partir de cas réels.",
            // Texte pour Footer.js
            "© 2024 Tous droits réservés.": "© 2024 Tous droits réservés.",
            // Texte pour Home.js
            "Solène Delourme": "Solène Delourme",
            "Ingénieure en Informatique": "Ingénieure en Informatique",
            "Master en Informatique Biomédicale": "Master en Informatique Biomédicale",
            "Spécialisée en IA et NLP": "Spécialisée en IA et NLP",
            "Bienvenue sur mon site !": "Bienvenue sur mon site !",
            "Vous y trouverez mes projets et mes publications.": "Vous y trouverez mes projets et mes publications.",
            "Je suis activement à la recherche d'opportunités d'emploi. Mon CV et mon résumé sont disponibles ci-dessous.": "Je suis activement à la recherche d'opportunités d'emploi. Mon CV et mon résumé sont disponibles ci-dessous.",
            "N'hésitez pas à découvrir mon travail et à prendre contact avec moi !": "N'hésitez pas à découvrir mon travail et à prendre contact avec moi !",
            "En apprendre plus sur moi": "En apprendre plus sur moi",
            "Voir mes projets": "Voir mes projets",
            "6 mois": "6 mois",
            "1 mois": "1 mois",
            "2 mois": "2 mois",
            "3 mois": "3 mois",
            "5 mois": "5 mois",
            "1 semaine": "1 semaine",
            "2 semaines": "2 semaines",
            "Ce que je veux faire ? Ce sont les projets concrets qui m'attirent. J’ai exploré beaucoup de domaines comme l’IA, le NLP et le web, et je suis ouverte à un CDI ou à une thèse. Ce n’est pas le type de poste qui compte pour moi, mais le projet derrière : s’il m’intéresse et que je pense pouvoir y contribuer efficacement, c’est là où je veux être.": "Ce que je veux faire ? Ce sont les projets concrets qui m'attirent. J’ai exploré beaucoup de domaines comme l’IA, le NLP et le web, et je suis ouverte à un CDI ou à une thèse. Ce n’est pas le type de poste qui compte pour moi, mais le projet derrière : s’il m’intéresse et que je pense pouvoir y contribuer efficacement, c’est là où je veux être.",
            "En tant que junior, je veux continuer à apprendre, explorer et peut-être me spécialiser avec le temps. Mon premier emploi ne définira peut-être pas toute ma carrière, mais je suis convaincue qu’il m’apportera les expériences nécessaires pour construire ma voie.": "En tant que junior, je veux continuer à apprendre, explorer et peut-être me spécialiser avec le temps. Mon premier emploi ne définira peut-être pas toute ma carrière, mais je suis convaincue qu’il m’apportera les expériences nécessaires pour construire ma voie."
        },
    },
    en: {
        translation: {

            "Accueil": "Home",
            "À Propos": "About",
            "Projets": "Projects",
            "Publications": "Publications",
            "Contact": "Contact",
            "Tous": "All",
            "Scolaire": "School",
            "Professionnel": "Professional",
            "Personnel": "Personal",
            "Public": "Public",
            "Privé": "Private",
            "Voir les Détails": "View Details",
            "Voir sur GitHub": "View on GitHub",
            "Kaggle": "Kaggle",
            "Voir moins": "Show less",
            "Voir plus": "Show more",
            "Projet fait en groupe": "Project done in a group",
            "Projet fait en groupe de quatre": "Project done in a group of four",
            "Projet fait seule": "Project done alone",
            "Revue": "Journal",
            "Lire la Publication": "Read Publication",

            "À Propos de Moi": "About Me",
            "Ma Photo": "My Photo",
            "Télécharger Mon CV (Français)": "Download My CV (French)",
            "Télécharger Mon Résumé (Anglais)": "Download My Resume (English)",
            "Je suis ingénieure en informatique biomédicale, diplômée de l’EPITA et avec un Master de La Sorbonne Université, et ce parcours a été bien plus qu’un simple diplôme. Il représente pour moi une quête de sens, d’adaptabilité et de dépassement de soi.":
                "I am a biomedical computer engineer, graduated from EPITA and with a Master’s degree from Sorbonne University, and this journey has been much more than just a diploma. It represents a quest for meaning, adaptability, and self-improvement.",
            "Enfant, je voulais devenir professeur, d’abord des écoles, puis de mathématiques. Mais un jour, un conseil m’a ouvert une autre voie : « Ne te ferme aucune porte. » C’est comme ça que j’ai découvert le monde de l’ingénierie, où j’ai appris à résoudre des problèmes concrets, à collaborer et à innover.":
                "As a child, I wanted to become a teacher, first in primary schools, then in mathematics. But one day, advice opened another path for me: 'Keep all doors open.' That’s how I discovered the world of engineering, where I learned to solve real-world problems, collaborate, and innovate.",
            "Pendant mes études, je me suis concentrée sur l’apprentissage académique et collectif, réalisant peu de projets personnels. Depuis que j’ai terminé, j’ai pu en faire deux : un projet lié à la préparation de mon TOEIC et ce site web. Ces expériences m’ont permis de réaliser que « faire un projet pour faire un projet » ne m’intéresse pas : j’ai besoin de savoir que ce que je crée a une utilité réelle.":
                "During my studies, I focused on academic and collective learning, completing few personal projects. Since graduating, I’ve done two: a project related to TOEIC preparation and this website. These experiences made me realize that 'doing a project for the sake of doing a project' doesn’t interest me: I need to know that what I create has real utility.",
            "Travailler dans le domaine de la santé est pour moi une évidence : chaque projet a un sens. Aider des patients, des scientifiques ou des chercheurs, c’est apporter ma pierre à l’édifice. Même un résultat négatif est une avancée, car il permet de mieux comprendre et de progresser.":
                "Working in the health sector is an obvious choice for me: every project has meaning. Helping patients, scientists, or researchers means contributing to progress. Even a negative result is a step forward because it leads to better understanding and improvement.",
            "Je suis curieuse de nature et convaincue que sortir de ma zone de confort m’a toujours enrichie. Mes expériences au LIMICS et chez VO2 Group m’ont permis d’explorer des domaines variés, où mes superviseurs pourront confirmer ma persévérance et ma motivation : « Quand on veut, on peut. »":
                "I am naturally curious and convinced that stepping out of my comfort zone has always enriched me. My experiences at LIMICS and VO2 Group allowed me to explore various fields, where my supervisors can vouch for my perseverance and motivation: 'Where there’s a will, there’s a way.'",
            "Ce que je veux faire ? Peut-être que je ne le sais pas encore exactement. Mais ce que je sais, c’est que je suis prête à apprendre, à m’adapter et à relever les défis qui viendront. Mon premier emploi ne définira peut-être pas toute ma carrière, mais je suis convaincue qu’il m’apportera les expériences nécessaires pour construire ma voie.":
                "What do I want to do? Maybe I don’t know exactly yet. But I do know that I am ready to learn, adapt, and face the challenges ahead. My first job may not define my entire career, but I am confident it will provide the experiences I need to build my path.",
            "Lire moins": "Read less",
            "Lire plus": "Read more",
            "Contact Me": "Contact Me",
            "Message envoyé avec succès!": "Message sent successfully!",
            "Erreur lors de l'envoi du message. Essayez encore": "Failed to send message. Please try again.",
            "Nom Prénom": "Name",
            "Email": "Email",
            "Message": "Message",
            "Entrez votre prénom": "Enter your name",
            "Entrez votre email": "Enter your email",
            "Entrez votre message": "Enter your message",
            "Envoyez votre Message": "Send your Message",


            // Publications
            //"Measured Performance and Healthcare Professional Perception of Large Language Models Used as Clinical Decision Support Systems: A Scoping Review": "Performance Mesurée et Perception des Professionnels de Santé des Grands Modèles de Langage Utilisés comme Systèmes de Support à la Décision Clinique : Une Revue de Portée",
            //"Studies in Health Technology and Informatics": "Études en Technologie et Informatique de Santé",
            "DEFT 2024 - Défi de fouille de données textuelles : Répondre automatiquement à des questionnaires à choix multiples issus d'annales d'examens de pharmacie avec des petits LLMs": "DEFT 2024 - Défi de fouille de données textuelles : Répondre automatiquement à des questionnaires à choix multiples issus d'annales d'examens de pharmacie avec des petits LLMs",
            "TALN": "TALN",
            //"Leveraging Guideline-Based Clinical Decision Support Systems with Large Language Models: A Case Study with Breast Cancer": "Exploiter les Systèmes de Support à la Décision Basés sur des Recommandations avec des Grands Modèles de Langage : Une Étude de Cas avec le Cancer du Sein",
            //"Methods of Information in Medicine": "Méthodes de l'Information en Médecine",

            // Texte spécifique pour `Navbar.js`
            "English": "English",
            "Français": "French",

            // Texte spécifique pour `Projects.js`
            "DEFT 2024": "DEFT 2024",
            "1ère place à la Campagne DEFT 2024 (défi de fouille de texte) sur les QCMs de Pharmacie avec des petits LLMs. Le but était d’identifier les réponses correctes parmi cinq options, en respectant des contraintes strictes sur les modèles et les corpus utilisés.": "1st place in the DEFT 2024 Campaign (Text Mining Challenge) on Pharmacy MCQs with small LLMs. The goal was to identify the correct answers among five options while respecting strict constraints on the models and corpora used.",

            "LIMICS": "LIMICS",
            "Développement d'un système de questions/réponses basé sur un LLM dans le but de réutiliser le système d'aide à la décision Oncodoc pour la gestion thérapeutique du cancer du sein.": "Development of a question/answer system based on an LLM to reuse the Oncodoc decision support system for breast cancer therapeutic management.",

            "Apprentissage Automatique - Prédiction de Diabète": "Machine Learning - Diabetes Prediction",
            "Entraînement de modèles d’IA à partir d’un dataset de prédiction de diabète.": "Training AI models using a diabetes prediction dataset.",

            "ETL - Entrepôt de Données de Santé": "ETL - Health Data Warehouse",
            "Création d’un mini-entrepôt de données de santé sous le modèle OMOP CDMV5.4.": "Creation of a mini health data warehouse using the OMOP CDMV5.4 model.",

            "Système d’Aide à la Décision et Ontologie": "Decision Support System and Ontology",
            "Construction d’un système d’aide à la décision médicale et d’une ontologie pour l’hypertension. Pas de programmation.": "Construction of a medical decision support system and ontology for hypertension. No programming involved.",

            "Analyse des Tendances Temporelles de la Tuberculose": "Analysis of Tuberculosis Temporal Trends",
            "Étude des tendances temporelles de la tuberculose avant, pendant, et après la pandémie de COVID-19.": "Study of tuberculosis temporal trends before, during, and after the COVID-19 pandemic.",

            "Compétition Kaggle - Réseaux de Neurones": "Kaggle Competition - Neural Networks",
            "Détection d’image par réseaux de neurones. Deux compétitions faite sur Kaggle. Première : Navire à la mano, écrire un CNN à la main en moins de 30 couches pour la détection d'images de navires. Deuxième : réutiliser un CNN éxistant pour la détection d'images de navires.": "Image detection using neural networks. Two Kaggle competitions. First: Hand-coded CNN with fewer than 30 layers for ship image detection. Second: Reusing an existing CNN for ship image detection.",

            "Imagerie Médicale et IA": "Medical Imaging and AI",
            "Utilisation de réseaux de neurones de convolutions (CNNs) dans le but de détecter des tumeurs cérébrales dans des IRM de cerveaux de nourissons.": "Use of convolutional neural networks (CNNs) to detect brain tumors in infant brain MRIs.",

            "Génomique - Expression Génique": "Genomics - Gene Expression",
            "Analyse différentielle sur des données d’expression génique pour le cancer du sein triple négatif (TNBC).": "Differential analysis of gene expression data for triple-negative breast cancer (TNBC).",

            "Bio-informatique - Séquençage de Génome": "Bioinformatics - Genome Sequencing",
            "Séquencage et reconstruction de novo du génome.": "Sequencing and de novo genome reconstruction.",

            "IOT": "IOT",
            "Création d'une montre connectée sur un STM32": "Creation of a connected watch on an STM32",

            "VO2 Group": "VO2 Group",
            "Réappropriation et développement d'une application de priceur pour les obligations financières.": "Redevelopment and enhancement of a pricing application for financial bonds.",

            "42SH": "42SH",
            "Création d'un interpreteur Shell": "Creation of a Shell interpreter",

            "OCR - Optical Character Recognition": "OCR - Optical Character Recognition",
            "Création d'un outil de reconnaissance optique de caratére avec un réseau de neurone": "Creation of an optical character recognition tool using a neural network",

            "Jeu vidéo en Réalité Virtuelle": "Virtual Reality Video Game",
            "Création d'un jeu vidéo en réalité virtuelle": "Creation of a virtual reality video game",

            "Application entrainement au TOEIC": "TOEIC Training Application",
            "Création d’une application locale pour s’entraîner au TOEIC. L’application propose 20 chapitres spécifiques, différents modes d’entraînement, la prononciation des mots en anglais, une bibliothèque interactive pour apprendre des mots issus de livres téléchargés et des statistiques pour suivre les progrès. (démo disponible)": "Creation of a local application to train for the TOEIC. The application offers 20 specific chapters, various training modes, English word pronunciation, an interactive library to learn words from uploaded books, and statistics to track progress. (demo available)",

            "Optimisation NLP pour les dialogues SAMU": "NLP Optimization for SAMU Dialogues",
            "Projet d'optimisation d'un modèle de langue pour l'annotation des échanges entre médecins régulateurs du SAMU et les appelants. Le travail inclut l'annotation manuelle via l'outil Brat, l'entraînement et l'évaluation de modèles comme Dr-BERT/DrBERT-7GB et camila-ud/DrBERT-CASM2. Objectif : améliorer la compréhension des dialogues pour optimiser la gestion des urgences médicales.": "Optimization project of a language model for annotating exchanges between SAMU medical dispatchers and callers. The work includes manual annotation using the Brat tool, training and evaluating models such as Dr-BERT/DrBERT-7GB and camila-ud/DrBERT-CASM2. Objective: improve dialogue understanding to optimize the management of medical emergencies.",

            "Exploration des données sur la santé mentale - Kaggle Compétitions": "Exploring Mental Health Data - Kaggle Playground Series",
            "Participation à la compétition Kaggle Playground Series S4E11. L'objectif est d'explorer les données d'une enquête sur la santé mentale pour identifier les facteurs pouvant causer des épisodes de dépression. Le projet inclut la création de modèles d'apprentissage automatique et l'ingénierie des fonctionnalités sur des données synthétiques générées à partir de cas réels.": "Participation in the Kaggle Playground Series S4E11 competition. The objective is to explore data from a mental health survey to identify factors that may contribute to episodes of depression. The project involves building machine learning models and feature engineering on synthetically generated datasets based on real-world cases.",
            // Texte pour Footer.js
            "© 2024 Tous droits réservés.": "© 2024 All rights reserved.",

            // Texte pour Home.js
            "Solène Delourme": "Solène Delourme",
            "Ingénieure en Informatique": "Computer Engineer",
            "Master en Informatique Biomédicale": "Master in Biomedical Informatics",
            "Spécialisée en IA et NLP": "Specialized in AI and NLP",
            "Bienvenue sur mon site !": "Welcome to my website!",
            "Vous y trouverez mes projets et mes publications.": "Here you can find my projects and publications.",
            "Je suis activement à la recherche d'opportunités d'emploi. Mon CV et mon résumé sont disponibles ci-dessous.": "I am actively looking for job opportunities. My CV and resume are available below.",
            "N'hésitez pas à découvrir mon travail et à prendre contact avec moi !": "Feel free to explore my work and get in touch with me!",
            "En apprendre plus sur moi": "Learn more about me",
            "Voir mes projets": "See my projects",
            "6 mois": "6 months",
            "1 mois": "1 month",
            "2 mois": "2 months",
            "3 mois": "3 months",
            "5 mois": "5 months",
            "1 semaine": "1 week",
            "2 semaines": "2 weeks",
            "Ce que je veux faire ? Ce sont les projets concrets qui m'attirent. J’ai exploré beaucoup de domaines comme l’IA, le NLP et le web, et je suis ouverte à un CDI ou à une thèse. Ce n’est pas le type de poste qui compte pour moi, mais le projet derrière : s’il m’intéresse et que je pense pouvoir y contribuer efficacement, c’est là où je veux être.": "What do I want to do? I am drawn to concrete projects. I’ve explored various fields such as AI, NLP, and web development, and I am open to either a full-time position or a PhD. For me, it’s not about the type of role but the project behind it: if it interests me and I believe I can contribute effectively, that’s where I want to be.",
            "En tant que junior, je veux continuer à apprendre, explorer et peut-être me spécialiser avec le temps. Mon premier emploi ne définira peut-être pas toute ma carrière, mais je suis convaincue qu’il m’apportera les expériences nécessaires pour construire ma voie.": "As a junior professional, I want to keep learning, exploring, and perhaps specialize over time. My first job may not define my entire career, but I am confident it will provide the experiences I need to shape my path."

        },
    },
};

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: "en",
    interpolation: { escapeValue: false },
});

export default i18n;