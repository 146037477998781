// src/components/Footer.js
import React from "react";
import { useTranslation } from "react-i18next";
import "./Footer.css";

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className="footer">
            <p>{t("© 2024 Tous droits réservés.")}</p>
        </footer>
    );
};

export default Footer;