import React from "react";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Projects from "./components/Projects";
import Publications from "./components/Publications";
import Footer from "./components/Footer";
import About from "./components/About";
import Contact from "./components/Contact";
import { useTranslation } from "react-i18next";
import "./i18n"; // Importer la configuration i18n

const App = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="App">
      <div className="language-switcher">
        <button onClick={() => changeLanguage("en")}>English</button>
        <button onClick={() => changeLanguage("fr")}>Français</button>
      </div>
      <Navbar />
      <Home />
      <About />
      <Projects />
      <Publications />
      <Contact />
      <Footer />
    </div>
  );
};


export default App;