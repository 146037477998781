// src/components/Publications.js

import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./Publications.css";

const Publications = () => {
    const { t } = useTranslation();

    const publications = [
        { id: 1, title: t("Measured Performance and Healthcare Professional Perception of Large Language Models Used as Clinical Decision Support Systems: A Scoping Review"), description: t(""), journal: t("Studies in Health Technology and Informatics"), year: 2024, link: "https://pubmed.ncbi.nlm.nih.gov/39176924/" },
        { id: 2, title: t("DEFT 2024 - Défi de fouille de données textuelles : Répondre automatiquement à des questionnaires à choix multiples issus d'annales d'examens de pharmacie avec des petits LLMs"), description: t(""), journal: t("TALN"), year: 2024, link: "https://talnarchives.atala.org/ateliers/2024/DEFT/3.pdf" },
        { id: 3, title: t("Leveraging Guideline-Based Clinical Decision Support Systems with Large Language Models: A Case Study with Breast Cancer"), description: t(""), journal: t("Methods of Information in Medicine"), year: 2024 },
    ];

    const publicationsRef = useRef(null); // Référence pour la section des publications
    const [showAll, setShowAll] = useState(false);

    // Gestion de l'affichage (limité ou complet)
    const displayedPublications = showAll ? publications : publications.slice(0, 2);

    const handleShowMore = () => {
        if (showAll && publicationsRef.current) {
            // Si on clique sur "Voir moins", remonte à la section des publications
            publicationsRef.current.scrollIntoView({ behavior: "smooth" });
        }
        setShowAll(!showAll);
    };

    return (
        <section id="publications" className="publications" ref={publicationsRef}>
            <h2>{t("Publications")}</h2>
            <div className="publication-list">
                {displayedPublications.map((pub) => (
                    <div key={pub.id} className="publication-card">
                        <h3>{pub.title}</h3>
                        <p>{pub.description}</p>
                        <p>
                            <strong>{t("Journal")}:</strong> {pub.journal} ({pub.year})
                        </p>
                        {pub.link && (
                            <a
                                href={pub.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="publication-link"
                            >
                                {t("Read Publication")}
                            </a>
                        )}
                    </div>
                ))}
            </div>
            <div className="show-more-container">
                <button
                    className="show-more-btn"
                    onClick={handleShowMore}
                >
                    {showAll ? t("Voir moins") : t("Voir plus")}
                </button>
            </div>
        </section>
    );
};

export default Publications;