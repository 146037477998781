import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";
import "./Contact.css";

const Contact = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });

    const { t } = useTranslation();
    const [status, setStatus] = useState(""); // SUCCESS, FAILED, or empty

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await emailjs.send(
                "service_g0fjhhn", // Remplacez par votre Service ID
                "template_85rawih", // Remplacez par votre Template ID
                {
                    name: formData.name,
                    email: formData.email,
                    message: formData.message,
                },
                "-p0fwn7OEhWCYpW3O" // Remplacez par votre Public Key
            );

            if (response.status === 200) {
                console.log("Email envoyé avec succès", response);
                setStatus("SUCCESS");
                setFormData({ name: "", email: "", message: "" }); // Réinitialiser le formulaire
            } else {
                console.error("Erreur lors de l'envoi de l'email", response);
                setStatus("FAILED");
            }
        } catch (error) {
            console.error("EmailJS erreur:", error);
            setStatus("FAILED");
        }
    };

    return (
        <section id="contact" className="contact">
            <h2>Me Contacter</h2>

            {status === "SUCCESS" && <p className="success-message">{t("Message envoyé avec succès!")}</p>}
            {status === "FAILED" && <p className="error-message">{t("Erreur lors de l'envoi du message. Essayez encore")}</p>}

            <form className="contact-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">{t("Nom Prénom")}</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder={t("Entrez votre prénom")}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder={t("Entrez votre email")}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="message">{t("Message")}</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        rows="5"
                        placeholder={t("Entrez votre message")}
                        required
                    ></textarea>
                </div>
                <button type="submit" className="contact-btn">{t("Envoyez votre Message")}</button>
            </form>
        </section>
    );
};

export default Contact;